<template>
  <div>

    <!-- Banner -->
    <banner
      :back-image="bannerImage"
      height="600"
      :title="$t('privacy.title')"
    />

    <div class="container">

      <!-- Part 1 -->
      <div class="my-5">
        <h1 class="mb-3">
          {{ $t('privacy.subtitle1') }}
        </h1>

        <p> {{ $t('privacy.text11') }} </p>

        <p>
          {{ $t('privacy.text12') }}
          <a @click="mailto()">
            <span>{{ socialEmail }}</span>
          </a>
        </p>

        <p>
          {{ $t('privacy.text13') }}
          <a @click="mailto()">
            <span>{{ socialEmail }}</span>
          </a>
        </p>
      </div>

      <!-- Part 2 -->
      <div class="mb-5">

        <h1 class="mb-3">
          {{ $t('privacy.subtitle2') }}
        </h1>

        <p> {{ $t('privacy.text21') }} </p>

        <p>
          {{ $t('privacy.text22') }}
          <a @click="mailto()">
            <span>{{ socialEmail }}</span>
          </a>
        </p>

        <p> {{ $t('privacy.text23') }} </p>

      </div>

      <!-- Part 3 -->
      <div class="mb-5">

        <h1 class="mb-3">
          {{ $t('privacy.subtitle3') }}
        </h1>

        <p> {{ $t('privacy.text31') }} </p>

        <ol>
          <li> {{ $t('privacy.text311') }} </li>

          <li> {{ $t('privacy.text312') }} </li>

          <li> {{ $t('privacy.text313') }} </li>
        </ol>

        <p>
          {{ $t('privacy.text32') }}

          <a @click="mailto()">
            <span>{{ socialEmail }}</span>
          </a>

          {{ $t('privacy.text32_1') }}
        </p>

        <ol>

          <li>
            {{ $t('privacy.text321') }}

            <a @click="mailto()">
              <span>{{ socialEmail }}</span>
            </a>
          </li>

          <li> {{ $t('privacy.text322') }} </li>

        </ol>

      </div>

      <!-- Part 4 -->
      <div class="mb-5">

        <h1 class="mb-3">
          {{ $t('privacy.subtitle4') }}
        </h1>

        <vue-good-table
          :rows="items"
          :columns="columns"
          :sort-options="{
            enabled: false,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column 3 Row 1 -->
            <span v-if="props.column.field === 'c3' && props.row.c3 === '' ">
              {{ $t('privacy.text4311') }}
              <br><br>
              {{ $t('privacy.text4312') }}
            </span>
            <!-- Column 4 Row 1-->
            <span v-if="props.column.field === 'c4' && props.row.c4 === '' ">
              {{ $t('privacy.text4411') }}
              <br><br>
              {{ $t('privacy.text4412') }}
            </span>
            <!-- Column 3 Row 2 -->
            <span v-if="props.column.field === 'c3' && props.row.c3 === ' ' ">
              {{ $t('privacy.text4321') }}
              <br><br>
              {{ $t('privacy.text4322') }}
            </span>
            <!-- Column 4 Row 2-->
            <span v-if="props.column.field === 'c4' && props.row.c4 === ' ' ">
              {{ $t('privacy.text4421') }}
              <br><br>
              {{ $t('privacy.text4422') }}
            </span>
            <!-- Default  -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>

      </div>

      <!-- Part 5 -->
      <div class="mb-5">

        <h1 class="mb-3">
          {{ $t('privacy.subtitle5') }}
        </h1>

        <p> {{ $t('privacy.text51') }} </p>

        <ol>
          <li> {{ $t('privacy.text511') }} </li>

          <li> {{ $t('privacy.text512') }} </li>

          <li> {{ $t('privacy.text513') }} </li>

          <li> {{ $t('privacy.text514') }} </li>

          <li> {{ $t('privacy.text515') }} </li>

          <li> {{ $t('privacy.text516') }} </li>

          <li> {{ $t('privacy.text517') }} </li>
        </ol>

        <p>
          {{ $t('privacy.text52') }}
          <a @click="mailto()">
            <span>{{ socialEmail }}</span>
          </a>

          {{ $t('privacy.text52_1') }}
        </p>

        <p>
          {{ $t('privacy.text53') }}

          <a @click="mailto()">
            <span>{{ socialEmail }}</span>
          </a>

          {{ $t('privacy.text54') }}
        </p>

      </div>

      <!-- Part 6 -->
      <div class="mb-5">

        <h1 class="mb-3">
          {{ $t('privacy.subtitle6') }}
        </h1>

        <p> {{ $t('privacy.text6') }} </p>

      </div>

      <!-- Part 7 -->
      <div class="mb-5">

        <h1 class="mb-3">
          {{ $t('privacy.subtitle7') }}
        </h1>

        <p> {{ $t('privacy.text7') }} </p>
      </div>

      <!-- Part 8 -->
      <div class="mb-5">

        <h1 class="mb-3">
          {{ $t('privacy.subtitle8') }}
        </h1>

        <p> {{ $t('privacy.text8') }} </p>

      </div>

    </div>
  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  name: 'Privacy',
  components: {
    Banner,
    VueGoodTable,
  },
  data() {
    return {
      bannerImage: '/static/limmat-images/policy/privacy.jpg',
      socialEmail: 'social@limmat-group.com',
      columns: [
        {
          label: this.$t('privacy.text41'),
          field: 'c1',
          thClass: 'text-center',
        },
        {
          label: this.$t('privacy.text42'),
          field: 'c2',
          thClass: 'text-center',
        },
        {
          label: this.$t('privacy.text43'),
          field: 'c3',
          thClass: 'text-center',
        },
        {
          label: this.$t('privacy.text44'),
          field: 'c4',
          thClass: 'text-center',
        },
        {
          label: this.$t('privacy.text45'),
          field: 'c5',
          thClass: 'text-center',
        },
      ],
      items: [
        {
          c1: this.$t('privacy.text411'),
          c2: this.$t('privacy.text421'),
          c3: '',
          c4: '',
          c5: this.$t('privacy.text451'),
        },
        {
          c1: this.$t('privacy.text412'),
          c2: this.$t('privacy.text422'),
          c3: ' ',
          c4: ' ',
          c5: this.$t('privacy.text452'),
        },
      ],
    }
  },
  methods: {
    mailto() {
      window.open('mailto:social@limmat-group.com', '_blank')
    },
  },
}
</script>

<style scoped>

  span {
    font-size: 1.1rem;
  }

  a {
    color:#ff8f00 !important;
  }

  @media (max-width: 700px){
    h1 {
      font-size: 40px;
    }

    span {
      font-size: 0.9rem;
    }
  }

</style>
